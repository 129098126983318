import React from "react"
import Title from "../Title"
import styles from "../../css/about.module.css"
import Img from "gatsby-image"
// import img from "../../images/defaultBcg.jpeg"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const { aboutImage } = useStaticQuery(
    graphql`
      query {
        aboutImage: file(relativePath: { eq: "defaultBcg.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <section className={styles.about}>
      <Title title="about" subtitle="us" />
      <div className={styles.aboutCenter}>
        <article className={styles.aboutImg}>
          <div className={styles.imgContainer}>
            {/* <img src={img} alt="about company" /> */}
            <Img
              fluid={aboutImage.childImageSharp.fluid}
              alt="PMQA"
            />
          </div>
        </article>
        <article className={styles.aboutInfo}>
          <h4>explore the difference</h4>
          <p>
          PMQA is a non–profit association, which promotes the professional interests of its members, who are engaged in management and quality.

              Our Goal and Objectives
              To promote the professional interests of its members
              To improve knowledge and skills of members in relevant interest areas
              To provide an outlet for professional networking and social interactions between members and the public at large
              To establish relationships with other like-minded organizations, with the intent of promoting the Association’s interests
          </p>
          <p>
          Collaboratively support each other in advancing their professional goals by opening new doors in the Canadian job market.  This is also a good opportunity for newcomers to Canada to immerse themselves in the Canadian experience, by learning from and seeking support from other like-minded professionals.

              <ui>Association Committees</ui>
              <ui>Membership</ui>
              Association Structure and operation
              Monthly Meetings
              Public Relations
              Networking and Mentoring
              Professional Development
              Financial and Legal
          </p>
          <button type="button" className="btn-primary">
            read more
          </button>
        </article>
      </div>
    </section>
  )
}

export default About
