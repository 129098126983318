import React from "react"
import Title from "../Title"
import styles from "../../css/items.module.css"
import Event from "../Events/Event"
import AniLink from "gatsby-plugin-transition-link/AniLink"
const Featured = ({ events }) => {
  // const tours = data.featuredTours.edges

  return (
    <section className={styles.tours}>
      <Title title="featured" subtitle="PMQA events" />
      <div className={styles.center}>
        {events.map(({ node }) => {
          return <Event key={node.contentful_id} event={node} />
        })}
      </div>
      <AniLink fade to="/events" className="btn-primary">
        all events
      </AniLink>
    </section>
  )
}

export default Featured