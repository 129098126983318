import React, { memo } from "react"
import Layout from "../components/Layout"
import SimpleHero from "../components/SimpleHero"
import Banner from "../components/Banner"
import { Link } from "gatsby"
import Services from "../components/Home/Services"
import About from "../components/Home/About"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import Featured from "../components/Home/Featured"
import SEO from "../components/SEO"
export default memo(({ data }) => {
  const events = data.events.edges
  return (
    <Layout>
      <SEO title="Home" />
      <StyledHero home="true" img={data.defaultBcg.childImageSharp.fluid}>
        <Banner
          title="Lets meet in our weekly PNC sessions"
          info="Saturday , Sunday each week , RichmondHill and North york,  reserve your spot through our Mobile App"
        >
          <AniLink fade to="/events" className="btn-white">
            explore events
          </AniLink>
        </Banner>
      </StyledHero>
      <About />
      <Services />
      <Featured events={events} />
    </Layout>
  )
})

export const query = graphql`
  {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    events: allContentfulPmqaEvents(filter: { featured: { eq: true } }) {
      edges {
        node {
          name
          price
          slug
          country
          contentful_id
          days
          images {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
