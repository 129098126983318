import React from "react"
import {  IoIosSchool } from "react-icons/io"
import {  MdSupervisorAccount, MdAllInclusive } from "react-icons/md"

export default [
  {
    icon: <MdSupervisorAccount />,
    title: "Mentoring",
    text:
      "Helping you reach your full potential. ",
  },
  {
    icon: <IoIosSchool />,
    title: "Training",
    text:
      "Get the best in class training from our highly skilled yet voluteer trainers. ",
  },
  {
    icon: <MdAllInclusive />,
    title: "Networking",
    text:
      "Meet and network with other professionals in your field. ",
  },
]
